import { VNode } from "vue";
/**
 * This functions gets the vnode traverse all child vnodes and add unique ids.
 * @param el : Parent Vnode and childrens if it has
 * @param uniqueIdCounter : It is state
 * @param prefix : Prefix
 */
export default function modifyAllIds(
  el: VNode,
  uniqueIdCounter: Map<string, number>,
  prefix
) {
  const changeAllRecursive = (el: VNode) => {
    // control directly
    const designId = el?.data?.props?.designId;
    if (designId) {
      let postfix: number | undefined = uniqueIdCounter.get(designId);
      uniqueIdCounter.set(designId, !postfix ? 1 : ++postfix);
      postfix = uniqueIdCounter.get(designId);

      if (!el.data) {
        el.data = {};
      }
      if (!el.data.attrs) {
        el.data.attrs = {};
      }
      el.data.attrs.id = prefix + designId + postfix;
    }

    if (el.children?.length) {
      // control for children
      el.children.forEach((el) => changeAllRecursive(el));
    }
  };

  changeAllRecursive(el);
}
