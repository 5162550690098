import DesignComponent from "./designComponent";

export default new DesignComponent({
  info: {
    title: "Spacer",
    img: require(`../../../assets/img/icons/spacer.svg`),
  },
  structure: {
    tag: "div",
    text: "S P A C E R",
    data: {
      props: {
        designId: "spacer",
        configurable: "Spacer",
      },
      attrs: {
        draggable: true,
      },
      style: {
        height: "50px",
        border: "1px dashed gray",
        "background-color": "white",
        color: "gray",
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
        "flex-shrink": 0,
      },
      class: [],
    },
  },
  settings: [
    {
      label: "Height",
      inputType: "text",
      get: (vnode: any) => vnode.data.style.height.replace("px", ""),
      set: (vnode: any, value) => (vnode.data.style.height = value + "px"),
    },
  ],
});
