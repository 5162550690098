import { VNode } from "vue";

const findVNodeById = (el: VNode, id) => {
  // control directly
  if (el?.data?.attrs?.id === id) {
    return el;
  }

  if (el.children?.length) {
    // control for children
    const found = el.children.map((el) => findVNodeById(el, id)).find((b) => b);
    if (found) {
      return found;
    }
  }

  return false;
};

export default findVNodeById;
