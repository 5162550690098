import DesignComponent from "./designComponent";
import { VNode } from "vue/types/umd";
import { ionColors } from "./ion-specific/index";

export default new DesignComponent({
  info: {
    title: "Button",
    img: require(`../../../assets/img/icons/button.svg`),
  },
  structure: {
    tag: "ion-button",
    data: {
      attrs: {
        size: "default",
        fill: "solid",
        color: "primary",
        draggable: true,
      },
      style: {
        width: "100%",
        "--background": "#3880ff",
        color: "white",
        "font-size": "1em",
        "margin-right": "auto",
        "margin-left": "auto",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": "none",
        // 'z-index': 1,
        position: "relative",
      },
      props: {
        designId: "button",
        configurable: "Button",
      },
      domProps: {
        innerHTML: "BUTTON",
      },
      class: [],
      // 'class': ['btn', 'btn-primary']
    },
    children: [
      /* {
        tag: "span",
        text: "Button"
      }*/
    ],
  },
  settings: [
    {
      label: "Button Color",
      inputType: "select",
      options: ionColors,
      get: (vnode: VNode) => vnode.data.attrs.color,
      set: (vnode: VNode, value) => (vnode.data.attrs.color = value),
    },
    {
      label: "Content",
      inputType: "text",
      get: (vnode: VNode) => vnode.data.domProps.innerHTML,
      set: (vnode: VNode, value) => (vnode.data.domProps.innerHTML = value),
    },
    {
      label: "Size",
      inputType: "select",
      options: [
        { text: "Small", value: "small" },
        { text: "Default", value: "default" },
        { text: "Large", value: "large" },
      ],
      get: (vnode: VNode) => vnode.data.attrs.size,
      set: (vnode: VNode, value) => (vnode.data.attrs.size = value),
    },
    {
      label: "Fill",
      inputType: "select",
      options: [
        { text: "Solid", value: "solid" },
        { text: "Outline", value: "outline" },
        { text: "Clear", value: "clear" },
      ],
      get: (vnode: VNode) => vnode.data.attrs.fill,
      set: (vnode: VNode, value) => (vnode.data.attrs.fill = value),
    },

    {
      label: "Font Size",
      inputType: "select",
      options: [
        { value: "1em", text: 1 },
        { value: "1.1em", text: 2 },
        { value: "1.3em", text: 3 },
        { value: "1.4em", text: 4 },
        { value: "1.5em", text: 5 },
        { value: "1.6em", text: 6 },
        { value: "1.7em", text: 7 },
        { value: "1.8em", text: 8 },
        { value: "1.9em", text: 9 },
        { value: "2em", text: 10 },
      ],
      get: (vnode: VNode) => vnode.data.style["font-size"],
      set: (vnode: VNode, value) => (vnode.data.style["font-size"] = value),
    },
  ],
});
