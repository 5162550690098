<script>
import main from "commonsfrontend/src/maker/vue-files/main";
export default {
  extends: main,
  metaInfo: {
    title: "GameMaker",
    titleTemplate: "%s - Codementum",
  },
};
</script>

<style lang="scss">
$primaryBlue: rgb(10, 80, 186);
$secondaryBlue: rgb(86, 223, 241);
$thirdBlue: #00f0ff;
$middleBlue: #3098d6;

// .design-group .title {
//   // color: #fff !important;
//   //  background: rgb(201, 214, 255) !important;
// }

header#CodementumNavbarHeader {
  img {
    filter: brightness(0) invert(1);
  }

  background-color: unset !important;
  background: $middleBlue !important;

  .user-settings button {
    color: $primaryBlue;

    &.activated,
    &:hover {
      background: $primaryBlue;
      color: white;
      & svg {
        fill: white;
      }
    }

    & svg {
      fill: $primaryBlue;
    }

    &.disabled {
      cursor: initial;
      //color: #802959;
      background-color: #63b7e8 !important;
      svg {
        // fill: #802959;
      }
    }
  }
  ul li .dropbtn:hover {
    background-color: $primaryBlue !important;
  }
}
.btn-mission {
  background-color: $primaryBlue !important;
  &:hover {
    color: $secondaryBlue !important;
  }
}

.top {
  border-bottom: 1px solid $middleBlue !important;
  .header:after {
    background-color: $middleBlue !important;
  }
}
.top-tasklist .top-tasklist-content ul li button.done {
  background-color: $primaryBlue !important;
}
.top-tasklist .top-tasklist-content ul li button.current {
  background-color: $secondaryBlue !important;
}
</style>
